.services {
  /* background-image: url("/public/images/home/home-hero.png"); */
}

.flexCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-bottom: 1rem;
}

.gradientText {
  background: #8fa7fb;
  background: linear-gradient(to right, #8fa7fb 0%, #000080 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.centerText {
  text-align: center;
}

.flex {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 3rem 0;
  gap: 1rem;
  /* border: 1px solid red; */
}

.serviceImages {
  width: 50%;
  /* border: 1px solid blue; */
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 1rem;
}

.serviceImages img {
  width: 14em;
  /* margin: 1rem; */
  /* border: 1px solid blue; */
}
.serviceDescription {
  width: 40%;
  background-color: #eeeeee;
  border: 1px dashed lightgray;
  padding: 3rem;
  line-height: 2rem;
}

@media screen and (max-width: 750px) {
  .flex {
    flex-direction: column;
    width: 100%;
  }
  .serviceDescription {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .serviceImages {
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }

  .smallWidth {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .flex {
    flex-direction: column;
  }

  .serviceImages img {
    width: 20em;
  }
}
