.productContainer {
  margin-top: 4.5rem;
}

.productsSection {
  margin-top: 3rem;
}


.centerText {
  /* margin-bottom: 2rem; */
}

.product {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  /* border: 1px solid blue; */
}

.product:hover {
  background-color: #fafafa;
}

.productLogo {
  width: 15rem;
  height: 15rem;
  align-self: center;
  /* border: 1px solid red; */
}

.productLink {
  color: black;
  text-decoration: none;
}


.card {
  padding: 5;

}

.cardContainer {
 margin-top: 20px;
 margin-bottom: 50px;
  display: flex;
   justify-content: space-between;
   flex-direction: row !important;
   align-items: center;
   gap: 30px !important;
}

@media screen and (max-width: 750px) {
  .productLogo {
    align-self: flex-start;
    margin-left: 2rem;
    width: 10rem;
  height: 10rem;
  }

  .card {
    padding: 5 !important;
  }

  .cardContainer {
    display: flex;
     justify-content: center;
     flex-direction: column !important;
    gap: 20px;
  }
}
