.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.d3ds {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Each D box */
.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 10rem;
  color: #3e3e8d;
  border-bottom: 4px solid #3e3e8d;
  height: 13rem;
  width: 15rem;
  gap: 0.7rem;
  padding: 1rem;
}

.hide {
  display: block;
  color: white;
}

.box:hover,
.box ~ .hide {
  display: block;
  color: white;
  background-color: #3e3e8d;
  transition: 0.7s ease-in-out;
}
hr {
  width: 2.5rem;
}
