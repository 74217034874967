.container {
  width: 100%;
  background-color: #ededff;
  padding: 2rem 0;
  background-image: url("/public/images/home/set-apart-bg.svg");
  background-repeat: no-repeat;
  background-size: auto;
}
.letterSpaced {
  letter-spacing: 4px;
}
.items {
  display: flex;
  margin-top: 2rem;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
}
.items .item {
  width: 45%;
  padding: 2rem;
  border-radius: 10px;
}

.items .item:hover {
  background-color: #d4d4f3;
  transition: 0.7s ease-in-out;
}

.items .item img {
  width: 2rem;
}

.items .item .title {
  letter-spacing: normal;
  color: #000080;
}

.qualityAssurance {
  display: flex;
  padding: 2rem 0;
  justify-content: space-between;
  gap: 2rem;
}

.qualityAssurance img {
  width: 50%;
  height: 100%;
}

@media screen and (max-width: 750px) {
  .container {
    padding: 1rem;
  }
  .items {
    flex-direction: column;
    margin-top: 1rem;
    gap: 0;
  }

  .items .item {
    width: 100%;
  }

  .qualityAssurance {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    justify-content: center;
    align-items: center;
  }
}
