.footerContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background: #050503;
}

footer {
  display: flex;
  flex-direction: row;
  color: white;
  justify-content: space-between;
  padding: 3rem;
  flex-wrap: wrap;
  gap: 1rem;
}

footer div {
  display: flex;
  flex-direction: column;
}

.linkTitle {
  margin-bottom: 15px;
}

footer a {
  color: white;
  text-decoration: none;
}

.footerSocials {
  display: flex;
  flex-direction: row;
  gap: 1em;
  margin-top: 1em;
}

.footerSocials a {
  color: white;
}

.copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #050503;
  width: 100%;
  color: white;
  border: white;
  padding: 5px 0;
}

@media only screen and (max-width: 500px) {
  footer {
    /* flex-direction: column; */
    flex-wrap: wrap;
    gap: 3rem;
    justify-content: flex-start;
  }

  .footerSocials {
    align-self: center;
  }
}
