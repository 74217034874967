.flexCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  gap: 1rem;
  /* border: 2px solid red; */
}

.header {
  background: linear-gradient(to right, #8fa7fb 0%, #000080 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
