.careersHero {
  margin-top: 4.5rem;
  background-color: white;
}

.careersHeader {
  margin: 2rem;
  text-align: center;
  padding-top: 2rem;
}

.container {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  gap: 2rem;
}

.companyInfo {
  display: flex;
  flex-direction: column;
  width: 30%;
  align-self: center;
}

.openings {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  width: 100%;
}

.openings > div {
  width: 100%;
  padding: 0.5rem 2rem;
  background-color: #ececec;
}

.openingTitle {
  font-size: large;
}

.openingTitle p {
  background-color: red;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.row button {
  text-transform: none;
  padding: 0.5rem 4rem;
  background-color: #000080;
}

.row p {
  color: purple;
}

@media only screen and (max-width: 750px) {
  .container {
    flex-direction: column;
  }
  .opportunities {
    flex-direction: column;
    width: 100%;
  }

  .companyInfo {
    width: 100%;
  }
  .row {
    flex-direction: column;
    gap: 1rem;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .openings {
    flex-direction: column;
    width: 100%;
  }
  .openings .row a button {
    align-self: flex-end;
  }

  .openings ul {
    padding-left: 15px;
  }
}
