.heroSection {
  background-size: cover;
  background-repeat: no-repeat;
  height: 85vh;
  background-position: center center;
  color: white;
  display: flex;
  flex-direction: column;
}

.hero div:first-child {
  width: 40em;
  display: flex;
  flex-direction: column;
}

.hero div:first-child > div {
  display: flex;
  justify-content: flex-start;
  gap: 2em;
}

.heroSection button {
  text-transform: none;
  margin-right: 10px;
  margin-top: 2rem;
  padding: 0.6rem 1.5rem;
  border-radius: 23px;
}

.content {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

.content h4 {
  font-weight: 700;
  line-height: 50px;
}

@media only screen and (max-width: 750px) {
  .heroSection {
    justify-content: center;
    align-items: center;
    height: max-content;
  }
  .content {
    width: 95%;
    text-align: center;
    margin-top: 1rem;
    justify-content: center;
    align-items: center;
  }

  .heroSection button {
    margin-top: 1rem;
  }
}
