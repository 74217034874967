.container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 4rem;
}

.teamSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
}

/* Flip card */
.flipCard {
  background-color: transparent;
  width: 300px;
  height: 350px;
  border-radius: 1rem;
  /* perspective: 1000px; */
}

.flipCardInner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
}

.flipCard:hover .flipCardInner {
  transform: rotateY(180deg);
  transition: 1s ease-in-out;
  border-radius: 1rem;
}

.flipCardFront,
.flipCardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 1rem;
}

.flipCardFront {
  background-color: #bbb;
  color: black;
  border-radius: 1rem;
}

.flipCardFront img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;
}

.flipCardBack {
  background-color: #202020;
  color: white;
  transform: rotateY(180deg);
  border-radius: 1rem;
  padding: 2rem;
}

.flipCardBack :not(.icon) {
  color: white;
  font-size: small !important;
}

.designation {
  border-radius: 8px;
  background: rgba(217, 217, 217, 0.1);
  box-shadow: -20.600000381469727px 20.600000381469727px 20.600000381469727px
      0px rgba(255, 255, 255, 0.1) inset,
    20.600000381469727px -20.600000381469727px 20.600000381469727px 0px rgba(
        165,
        165,
        165,
        0.1
      ) inset;
  backdrop-filter: blur(15px);
  margin: auto;
  padding: 0.3rem 0;
  background: linear-gradient(to right, black, #5756a8);

  width: 90%;
  margin: auto;
  padding: 0.4rem 0;
  z-index: 3;
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}
.designation p,
.designation span {
  color: white;
}
