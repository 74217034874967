.background {
  background-image: url(/public/images/contact/contact.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  padding: 2rem;
  margin-top: 4.5rem;
}

.contactContent {
  background-color: #ffff;
  padding: 2.3rem 2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  font-family: "Poppins", sans-serif;
}

/* .contactForm, */
.contact {
  width: auto;
  padding: 2.7rem;
  width: 45%;
}

.phoneLink {
  color: black;
  text-decoration: none;
  font-size: 12px;
}

.contactForm {
  width: 55%;
  padding: 2.7rem;
}

.contact h6 {
  line-height: 1.5;
  margin: 10px 0 40px 0 !important;
  font-weight: 700;
}

.contactForm,
.locationItem {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}

.formInput {
  margin: 2rem 0;
  width: 100%;
}

.formInput label {
  font-weight: bold;
}

.formInput input,
.formInput textarea {
  border: 1px solid #b8b8b8;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  margin: 7px 0;
}

::placeholder {
  font-family: "Poppins", sans-serif;
}

.formInput textarea {
  height: 100px;
}

.contactForm button {
  width: 100%;
  padding: 15px;
  background-color: #000080;
  color: #ffff;
  border: none;
  font-weight: bold;
  cursor: pointer;
}

.contactForm button:hover {
  transform: ease-in;
  transition: 2s;
  background-color: inherit;
  color: #000080;
  border: 1px solid #000080;
}

.locationItem {
  background-color: #ffff;
  display: flex;
  align-items: center;
  padding: 1rem;
  margin: 2rem 0;
}

.itemIcon {
  font-size: 20px !important;
  color: #cbcbcb;
  margin: 0 15px 0 0;
}

textarea {
  resize: none;
}
button {
  border-radius: 4px;
}

.bookMeeting {
  background-color: white;
  padding: 3rem;
  text-align: center;
  margin-top: 3rem;
}

.bookMeeting p {
  margin-bottom: 2rem;
}

.bookMeeting a {
  background-color: black;
  color: lightblue;
  text-decoration: none;
  padding: 0.8rem;
  margin-top: 1rem;
  border-radius: 2px;
}

.bookMeeting a:hover {
  transform: ease-in;
  transition: 2s;
  background-color: inherit;
  color: #000080;
  border: 1px solid #000080;
}

@media screen and (max-width: 900px) {
  .contactContent {
    flex-direction: column;
    padding: 0.7rem;
    width: 100%;
    margin: 0;
  }

  .contactForm,
  .contact {
    width: 100%;
    padding: 1.5rem;
  }

  .background {
    padding: 20px;
  }
}

.mapContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
}

@media screen and (max-width: 500px) {
  .contactContent {
    flex-direction: column;
    padding: 0.7rem;
    width: 100%;
  }
}
