.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5rem;
}

.ourProcess {
  padding: 4rem 0;
  background-image: url(/public/images/about/about-background-circle.png);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.ourProcess h4,
.ourProcess p {
  text-align: center;
  padding: 0.8rem 0;
}

.processImage {
  margin: 4rem 0 0 0;
  display: flex;
  justify-content: space-around;
}

.processImage img {
  margin: 0 10px;
}

.processImage img:first-child {
  margin: 2.3rem 0 0 0;
}

.processImage img:nth-child(2) {
  margin: 0 0 2.5rem 0;
}

.processImage img:nth-child(3) {
  margin: 0 0 3.3rem 0;
}

.processImage img:nth-child(4) {
  margin: 0 0 3rem 0;
}

.processImage img:last-child {
  margin: 2.3rem 0 0 0;
}

@media screen and (max-width: 750px) {
  .processImage {
    flex-direction: column;
  }
}
