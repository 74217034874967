.card {
  padding: 3rem;
  background: #ffffff;
  box-shadow: 4px -4px 19px 11px rgba(218, 218, 236, 0.15),
    -4px 4px 19px 11px rgba(218, 218, 236, 0.15);
  border-radius: 4px;
  width: 22rem;
  height: 20rem;
}

@media screen and (max-width: 835px) {
  .card {
    width: 100%;
    margin-top: 1rem;
    text-align: center;
  }
}

@media screen and (max-width: 750px) {
  .card {
    width: 100%;
    margin-top: 1rem;
  }
}
