.homeHero {
  background-image: url("/public/images/home/office2.jpg");
  background-repeat: none;
  background-size: cover;
  background-position: center;
  position: relative;
  animation: zoomin 20s 1;
  max-width: 100vw;
  overflow-x: hidden;
  color: white;
  height: 85vh;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: 4.5rem;
}

.heroOverlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(1, 1, 133, 0.5);
  z-index: 2;
  display: block;
}

@keyframes zoomin {
  0% {
    -webkit-transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.1);
  }
}

/* Number One Metaverse Section */

.aboutSection {
  padding: 4rem 0;
  background-image: url(/public/images/about/about-background-circle.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.aboutSection h4 {
  line-height: 1.5;
  padding: 0 0 2rem 0;
}

.about {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-evenly;
  /* border: 1px solid red; */
}

.img {
  width: 100%;
}

.aboutItem {
  width: 50%;
  /* border: 2px solid blue; */
}

.aboutText {
  display: flex;
  align-items: center;
}

.aboutText p {
  text-align: left;
}

/* Swiper js styles */
#app {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* swiper js style end */

/* End Number One Metaverse Section */

@media screen and (max-width: 750px) {
  .heroBackground {
    /* height: 33rem; */
    height: 75vh;
  }
  .homeContainer {
    max-width: 100%;
    padding: 0;
  }
  .heroWelcomeContent {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .heroWelcomeContent .homeHeroWelcomeText {
    width: 100%;
    text-align: center;
    padding: 0;
  }

  .about {
    flex-wrap: wrap;
  }

  .aboutItem {
    width: 100%;
  }

  .christmasTreeSection {
    position: absolute;
    left: 0rem;
    bottom: 0%;
    z-index: 1;
    display: flex;
    justify-content: flex-start;
    padding-left: 1rem;
  }

  .christmasTreeSection img:first-child {
    height: 10rem;
    align-self: flex-end;
  }

  .christmasTreeSection :not(img:first-child) {
    height: 4rem;
    width: 4rem;
  }
}
