.container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: rgba(212, 220, 255, 0.9);
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (prefers-reduced-motion: no-preference) {
  .logo {
    animation: App-logo-spin infinite 1s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
