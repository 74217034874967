.toolbar {
  max-width: 100vw;
  overflow: hidden;
}
.logo {
  margin-left: 15rem;
  position: relative;
  margin-top: 1rem;
}

.regular {
  text-transform: none;
  text-decoration: none;
  margin: 1rem;
  /* font-weight: bold; */
  font-size: 0.9rem;
  border-bottom: 2px solid white;
  color: #3f39a0;
  padding: 10px;
  padding-bottom: 5px;
}

.active {
  text-transform: none;
  text-decoration: none;
  margin: 1rem;
  font-weight: bold;
  font-size: 0.9rem;
  border-bottom: 2px solid #000080;
  padding: 10px;
  padding-bottom: 5px;
}

.regular:hover {
  border-bottom: 2px solid #5756a8;
  transition: 2s ease;
}

a {
  text-transform: none;
}
.contactBtn {
  background-color: #5756a8;
  color: #ffffff !important;
  border-radius: 20px;
  padding: 1px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: none;
}

.drawerLinks {
  display: block;
  text-align: left;
  margin-left: 4rem;
  margin-bottom: 1.5rem;
  text-decoration: none;
  color: #3f39a0;
}

.drawerLinks:visited {
  color: #3f39a0;
}

@media only screen and (max-width: 500px) {
  .logo {
    margin-left: 0px;
  }
}
