.snappImg {
  width: 2rem;
}

.heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15%;
  background-image: url("/public/images/products/bg.png");
  gap: 1rem;
  background-position-x: right;
  background-size: 50%;
  background-repeat: no-repeat;
  width: 100%;
  height: 50rem;
}
.leftContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}

.leftContent span {
  background-color: black;
  color: green;
  padding: 0 10px;
}

.playstoreBtn {
  width: 12rem;
}
.iPhone {
  width: 15rem;
}

.btnSection {
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
  padding-top: 8rem;
  width: 100%;
}

.btnSection a {
  border-radius: 10px !important;
  color: grey !important;
  border-color: grey !important;
  text-decoration: none;
  border: 1px solid grey;
  padding: 5px 15px;
}

.btnSection a:hover {
  background-color: lightgray;
  transition: 0.7s ease-in-out;
}

@media screen and (max-width: 750px) {
  .body {
    flex-direction: column;
    justify-self: center;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
    height: max-content;
  }
}
