.hero {
  height: 80vh;
  width: 100%;
  position: relative;
  margin-top: 4.5rem;
}

.videoOverlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.video {
  height: 100%;
  width: 100%;
}

/* End Hero Section */

/* Begin Our Story */
.gradientText {
  background: #8fa7fb;
  background: linear-gradient(to right, #8fa7fb 0%, #000080 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.centerText {
  text-align: center;
}

/* End Our Story */

/* Begin Core Value Section */
.coreValueSection {
  background-image: url(/public/images/about/about-background.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 4rem 0;
  color: #ffffff;
}

.coreValueSection h5 {
  margin: 0 0 3rem 0;
}

.coreValues {
  display: flex;
  gap: 4rem;
  justify-content: center;
  flex-wrap: wrap;
  color: #121215;
}

.coreValues img {
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
}

.valueHeaderContent {
  display: flex;
  align-items: center;
  margin: 0 0 3rem;
}

@media screen and (max-width: 750px) {
  .hero {
    height: fit-content;
    width: 100%;
    position: relative;
  }
  .video {
    min-width: 100%;
  }

  .aboutItem {
    margin-top: 1rem;
  }

  .homeContainer {
    padding: 0;
  }

  .heroBackground {
    height: max-content;
  }

  .heroWelcomeContent {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .heroWelcomeContent .homeHeroWelcomeText {
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .heroWelcomeContent .slideContainer {
    width: 100%;
  }

  .homeHeroWelcomeText {
    padding: 1.5rem 0;
  }
}

/* @media screen and (max-width: 800px) {
  .processImage {
    flex-direction: column;
  }

  .aboutItem {
    margin-top: 1rem;
  }
} */
