@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

p:not(footer p):not(footer a),
p:not(footer) a:not(p a) {
  color: #121215;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  max-width: 70%;
  margin: auto;
  padding: 2rem;
  position: relative;
}

.gradient-text {
  background: #8fa7fb;
  background: linear-gradient(to right, #8fa7fb 0%, #000080 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.splide__pagination__page.is-active {
  background: linear-gradient(
    97.62deg,
    #7721d6 31.08%,
    rgba(197, 57, 152, 0.46) 119.9%
  ) !important;
  border: 0.2px solid rgba(201, 198, 198, 0.5) !important;
}

.splide__pagination__page {
  background: linear-gradient(
    97.62deg,
    rgba(119, 33, 214, 0.25) 31.08%,
    rgba(197, 57, 152, 0.115) 119.9%
  ) !important;
  border: 0.2px solid rgba(201, 198, 198, 0.5) !important;
}

@media screen and (max-width: 750px) {
  .container {
    max-width: 90%;
    margin: auto;
    padding: 1rem;
    position: relative;
  }
}

/* scrollbar */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 128, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000080;
}
