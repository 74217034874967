.img {
  height: 10rem;
  align-self: left;
}

.content {
  width: 100%;
}

.flex {
  display: flex;
  gap: 2rem;
}

.gradientText {
  background: #8fa7fb;
  background: linear-gradient(to right, #8fa7fb 0%, #000080 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.centerText {
  text-align: center;
}
